import React, { useEffect, useState } from 'react';
import heroImage from "../../assets/home/hero.png";

function Hero() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div>
      <section
        className={`bg-center bg-no-repeat bg-gray-700 bg-blend-multiply transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
          <h1 className={`mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl transition-transform duration-1000 ${isVisible ? 'translate-y-0' : 'translate-y-10'}`}>
            Welcome to Angel Haven by the Creek!
          </h1>
          <p className={`mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48 transition-transform duration-1000 delay-200 ${isVisible ? 'translate-y-0' : 'translate-y-10'}`}>
            Nestled in Auburn WA, we provide extended in-home nursing and hospice care for your loved ones.
          </p>
          <div className={`flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 transition-transform duration-1000 delay-400 ${isVisible ? 'translate-y-0' : 'translate-y-10'}`}>
            <a
              href="/contact"
              className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400"
            >
              Contact Us!
            </a>
          </div>
          <p className={`mt-2 align-center transition-transform duration-1000 delay-600 ${isVisible ? 'translate-y-0' : 'translate-y-10'}`}>
            <span className="text-lg ml-4 text-white font-semibold">(253) 279-7919</span>
          </p>
        </div>
      </section>
    </div>
  );
}

export default Hero;
