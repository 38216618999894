import * as React from 'react';

function ServiceItem({ title, description, overview }) {
  return (
    <article className="flex flex-col justify-center w-full md:w-3/4 p-5 bg-white dark:bg-gray-800 shadow-lg rounded-lg transition-transform transform hover:scale-105 mb-10">
      <div className="flex flex-col grow text-xl text-gray-800 dark:text-gray-200">
        <h2 className="text-4xl text-center font-bold text-gray-800 dark:text-gray-200 mt-5 mb-5">
          {title}
        </h2>
        <p className="pl-5 mb-5">{overview}</p>
        <ul className="space-y-2 text-lg text-gray-500 dark:text-gray-400 list-disc list-inside pl-5">
          {description.map((item, index) => (
            <li key={index}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
}

export default function Service() {
  const services = [
    {
      title: "Resident Care",
      overview: "We provide a range of services to meet the needs of our residents. Our team of dedicated caregivers is committed to ensuring the health, safety, and well-being of each individual in our care. From personalized assistance with activities of daily living to regular health monitoring and wellness checks, we strive to provide comprehensive support that promotes independence and quality of life.",
      description: [
        "Personalized assistance with activities of daily living",
        "Medication management and administration",
        "Regular health monitoring and wellness checks"
      ]
    }, 
    {
      title: "Elderly Nutrition",
      overview: "Nutrition is a key component of overall health and well-being, especially for older adults. Our commitment to your family's well-being is at the heart of everything we do.",
      description: [
        "On-site registered nurse (RN) for medical assessments and treatments",
        "Home visiting doctor for all medical questions and needs.",
        "Wound care, medication administration, and other skilled nursing services",
        "Collaboration with external healthcare providers for specialized care needs"
      ]
    },
    {
      title: "Private Room and Comfortable Living",
      overview: "Our rooms are cozy like a normal home. We have a spacious and cozy private room for each resident. Comfortable living spaces designed to promote relaxation and well-being. Amenities such as outdoor patio and communal areas for socialization and leisure activities.",
      description: [
        "Spacious and cozy private rooms for each resident",
        "Comfortable living spaces designed to promote relaxation and well-being",
        "Amenities such as outdoor patio and communal areas for socialization and leisure activities",
      ]
    },
    {
      title: "Shuttle for Outings",
      overview: "Our shuttle service allows residents to move to nearby attractions, parks, shopping centers, and more. Scheduled outings and transportation services for recreational activities and community events. Opportunity for residents to stay engaged and explore new experiences in a safe and comfortable environment.",
      description: [
        "Scheduled outings and transportation services for recreational activities and community events",
        "Access to nearby attractions, parks, shopping centers, and more",
        "Opportunity for residents to stay engaged and explore new experiences in a safe and comfortable environment"
      ]
    },
    {
      title: "Medications Delivered to our Door",
      overview: "Convenient pharmacy services with medications delivered directly to our facility. Elimination of errands for residents and their families, ensuring seamless access to necessary medications. Peace of mind knowing medication needs are taken care of promptly and efficiently.",
      description: [
        "Convenient pharmacy services with medications delivered directly to our facility",
        "Elimination of errands for residents and their families, ensuring seamless access to necessary medications",
        "Peace of mind knowing medication needs are taken care of promptly and efficiently",
      ]
    },
    {
      title: "Grooming Services",
      overview: "We provide respectful and dignified care to help residents look and feel their best.",
      description: [
        "Assistance with grooming tasks such as shaving, haircuts, and nail care",
        "Support with personal hygiene routines, including showers and bathing",
        "Respectful and dignified care to help residents look and feel their best",
      ]
    },
    {
      title: "Caring Staff",
      overview: "Our team of dedicated caregivers is committed to ensuring the health, safety, and well-being of each individual in our care. From personalized assistance with activities of daily living to regular health monitoring and wellness checks, we strive to provide comprehensive support that promotes independence and quality of life.",
      description: [
        "Compassionate and experienced caregivers available 24/7",
        "Home visiting doctor for prompt medical attention and ongoing care",
        "On-site registered nurse (RN) for specialized medical support and supervision",
        "Emphasis on building trusting relationships and fostering a sense of belonging",
        "Respectful and dignified assistance with mobility, personal hygiene, and more"
      ]
    }
  ];

  return (
    <main className="container mx-auto p-5">
      <section className="flex flex-wrap justify-center gap-10">
        {services.map((service, index) => (
          <ServiceItem key={index} title={service.title} description={service.description} overview={service.overview} />
        ))}
      </section>
    </main>
  );
}
