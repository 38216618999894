import React from "react";
import family from "../../assets/home/family.png";

function IconWithText({ icon, text }) {
  return (
    <div className="flex gap-2 items-start leading-[162.5%] max-md:flex-wrap mt-5">
      <img
        loading="lazy"
        src={icon}
        alt="Bullet point icon"
        className="shrink-0 w-6 aspect-square"
      />
      <p className="max-w-[calc(100%-2rem)]">{text}</p>{" "}
      {/* Adjust max-width to leave space for gap */}
    </div>
  );
}

export default function About() {
  const iconTextData = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/efbd10c4799084d6c26f208df8f42b2116025b662fe3413ae7474be7b747cae1?apiKey=407d704e01f2493092f36d784e4d9811&",
      text: "On-site nurse and doctor that makes visits to the home for prompt medical attention",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/efbd10c4799084d6c26f208df8f42b2116025b662fe3413ae7474be7b747cae1?apiKey=407d704e01f2493092f36d784e4d9811&",
      text: "24-hour care to ensure round-the-clock support and assistance",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/efbd10c4799084d6c26f208df8f42b2116025b662fe3413ae7474be7b747cae1?apiKey=407d704e01f2493092f36d784e4d9811&",
      text: "Convenient pharmacy services, with medications delivered right to our door",
    },
  ];

  return (
    <section className="container my-5">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="relative w-full lg:w-1/2 transition-opacity duration-1000">
          <img
            loading="lazy"
            src={family}
            alt="Home Backround Image"
            className="object-cover w-full h-full filter opacity-100"
          />
        </div>
        <div className="flex flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full transition-opacity duration-1000">
          <div className="flex flex-col self-stretch px-5 my-auto text-base text-zinc-500 max-md:mt-10 max-md:max-w-full">
          <h2 className="text-center text-5xl leading-[58px] text-zinc-800 font-bold max-md:max-w-full max-md:text-4xl max-md:leading-[54px]">              About Us
            </h2>
            <p className="mt-4 text-lg leading-7 max-md:max-w-full">
              Welcome home to Angel Haven by the Creek! We're an adult extended
              family care home dedicated team committed to providing exceptional
              care for adults in need. With a focus on compassion and
              individualized attention, we create a warm, homelike environment
              where residents thrive. Discover the difference at Angel Haven by
              the creek, where we offer:
            </p>
            <div>
              {iconTextData.map((item, index) => (
                <IconWithText key={index} icon={item.icon} text={item.text} />
              ))}
            </div>
            <a href="/about">
            <button className="mx-auto text-center justify-center min-w-full py-4 mt-10 font-bold text-white bg-[linear-gradient(135deg,#7F9CF5_0%,#22D3EE_100%)] max-md:px-5 w-auto">
              Learn More
            </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
