import * as React from "react";
import { useState } from "react";

function ServiceCard({ icon, title, description }) {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`flex flex-col grow p-6 rounded-lg shadow-lg transform transition-transform duration-300 ${
        hovered ? "scale-105 shadow-2xl" : ""
      } bg-white dark:bg-gray-800 max-md:px-5 max-md:mt-6 max-md:max-w-full`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img src={icon} alt="Service icon" className="w-16 h-16 mb-4 mx-auto" />
      <h3 className="mt-2 text-center text-2xl font-semibold leading-6 text-gray-900 dark:text-white max-md:max-w-full">
        {title}
      </h3>
      <p className="mt-4 text-lg leading-7 text-gray-600 dark:text-gray-400 max-md:max-w-full">
        {description}
      </p>
    </div>

  );
}

const services = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/06f11300c377fe25290f872363e0d79dc6f05c80bd0395d4750079028c6a8aa3?apiKey=407d704e01f2493092f36d784e4d9811&",
    title: "Resident Care",
    description:
      "Personalized assistance with activities of daily living Medication management and administration Regular health monitoring and wellness checks",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7d136a9f9b14646df43e8f9a1555c379ff4789d40ba40c949cac5a17b0a8309c?apiKey=407d704e01f2493092f36d784e4d9811&",
    title: "Elderly Nutrition",
    description:
      "Our commitment to your family's well-being is at the heart of everything we do. From private rooms to home-cooked meals prepared with love, we strive to create an atmosphere that feels familiar and nurturing.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e42f34f3a33a88f98c79b0d625583379c2a55b3fbf94dbc801604c95a459d0db?apiKey=407d704e01f2493092f36d784e4d9811&",
    title: "Skilled Nursing",
    description:
      "Experienced nurse and caregivers on site 24/7. We provide a range of services, from medication management to wound care, to ensure your loved ones receive the care they need.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6498ed57e10de764267ce0f467abda42b0a8cb58adc7dd21515a44afffd82870?apiKey=407d704e01f2493092f36d784e4d9811&",
    title: "Caring Staff",
    description:
      "But beyond the practicalities, we value the bonds that make a house a home. That's why we encourage shared meals and family involvement, fostering a sense of community that uplifts spirits and brings joy to each day.",
  },
];

export default function Services() {
  return (
    <section className="container mx-auto">
      <h2 className="mt-10 text-center text-4xl font-bold text-gray-800 dark:text-white md:text-5xl">
        Our Services
      </h2>
      <p className="mt-8 text-center text-gray-500 dark:text-gray-400 mb-12">
        We provide a range of services to meet the needs of our residents.
        <a
          href="/services"
          className="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline ml-2"
        >
          See our full list of services
          <svg
            className="w-4 h-4 ml-2 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </p>

      <div className="grid gap-10 lg:grid-cols-2 max-md:grid-cols-1">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
    </section>
  );
}
