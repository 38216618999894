import * as React from "react";
import herrickFamilyImage from "../../assets/staff/herrickFamily.jpg";

function MissionStatement() {
  return (
    <section className="container mt-5 flex gap-5 max-md:flex-col max-md:gap-0">
      <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
        <div className="flex flex-col self-stretch px-5 my-auto max-md:mt-10 max-md:max-w-full">
          <h2 className="text-center text-5xl font-bold leading-[58.08px] text-zinc-800 max-md:max-w-full max-md:text-4xl">
            Mission Statement
          </h2>
          <p className="text-lg leading-7 text-neutral-500 max-md:max-w-full mt-5">
            With over 25 years of experience in Adult Family Home care, our
            dedicated team, led by an onsite Registered Nurse with over a decade
            of Intensive Care Training and a Physician that is able to make home
            visits if you so desire. We are here to support you every step of
            the way. Together, let's embark on this journey with compassion,
            warmth, and a shared commitment to providing the best possible care
            for your loved ones. Welcome to Angel Haven By The Creek – where
            hearts find a home.
          </p>
        </div>
      </div>
      <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
        <img
          loading="lazy"
          src={herrickFamilyImage}
          alt="Mission Statement"
          className="grow w-full max-md:mt-10 max-md:max-w-full"
        />
      </div>
    </section>
  );
}

export default MissionStatement;
