import React, { useState } from "react";
import menuData from "./menu.json";
import foodImage from "../../assets/amenities/food.png";

function FoodMenu() {
  const weeks = Object.keys(menuData);
  const [selectedWeek, setSelectedWeek] = useState(weeks[0]); // Set Week 1 as default

  const handleWeekChange = (e) => {
    setSelectedWeek(e.target.value);
  };

  // Transpose the menu data to have meals as columns and days as rows
  const transposeMenuData = (weekData) => {
    const transposedData = {};
    Object.entries(weekData).forEach(([day, meals]) => {
      Object.entries(meals).forEach(([meal, item]) => {
        if (!transposedData[meal]) {
          transposedData[meal] = {};
        }
        transposedData[meal][day] = item;
      });
    });
    return transposedData;
  };

  // Render table rows dynamically based on selected week
  const renderMenuRows = () => {
    const weekData = menuData[selectedWeek];
    const transposedData = transposeMenuData(weekData);
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const meals = ["Breakfast", "Lunch", "Dinner"];
    return days.map((day, index) => (
      <div
        key={index}
        className="grid grid-cols-4 px-4 py-3 text-sm text-black border-b border-gray-200 gap-x-4 dark:border-gray-700 bg-white"
      >
        <div className="text-gray-500 dark:text-gray-400">{day}</div>
        {meals.map((meal, index) => (
          <div key={index}>{transposedData[meal][day]}</div>
        ))}
      </div>
    ));
  };

  return (
    <div
      className="mt-10"
      style={{
        backgroundImage: `url(${foodImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        paddingBottom: "50px", // Add padding below the photo
        alt: "Food Menu Background Image",
      }}
    >
      <div className="container flex flex-col  bg-center h-screen justify-center px-4 md:px-0">
        <h2 className="w-full text-5xl font-bold leading-[58.08px] text-white max-md:max-w-full max-md:text-4xl text-center">
          Food Menu
        </h2>
        <caption className="text-lg font-semibold text-white text-center bg-transparent px-8 py-6">
          <p className="text-xl font-normal text-white">
            See our full menu, select a week to see what we're having to eat.
          </p>
        </caption>
        <form className="text-center">
          <select
            id="weekSelector"
            className="auto bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={selectedWeek}
            onChange={handleWeekChange}
          >
            {weeks.map((week, index) => (
              <option key={index} value={week}>
                {week}
              </option>
            ))}
          </select>
        </form>

        <div id="detailed-pricing" className="mt-10 overflow-x-auto">
          <div className="mx-auto">
            {/* Table headers */}
            <div className="grid grid-cols-4 px-4 p-7 text-sm font-medium text-gray-900 bg-gray-100 border-t border-b border-gray-200 gap-x-4 dark:bg-gray-800 dark:border-gray-700 dark:text-white">
              <div className="flex items-left">Day</div>
              {["Breakfast", "Lunch", "Dinner"].map((meal, index) => (
                <div key={index}>{meal}</div>
              ))}
            </div>
            {/* Render dynamic menu rows */}
            {renderMenuRows()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodMenu;
