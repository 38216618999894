import * as React from 'react';
import { useInView } from 'react-intersection-observer';

function HistoryItem({ date, title, description }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <li 
      ref={ref} 
      className={`mt-10 ms-4 transition-opacity duration-1000 ${
        inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      } max-md:px-4`}
    >
      <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
      <h3 className="pl-3 text-lg font-semibold text-gray-900 dark:text-white">
        {title}
      </h3>
      <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
        {description}
      </p>
    </li>
  );
  
}

export default function History() {
  const historyItems = [
    {
      date: 'February 2022',
      title: 'The History of Angel Haven: A Legacy of Care',
      description: 'For over 25 years, Angel Haven has been a trusted name in adult family home care, dedicated to providing compassionate and personalized support to the elderly and individuals in need of assisted living. The foundation of Angel Haven was built on a passion for quality care, with a clear vision to create a safe and nurturing environment where residents could thrive.',
    },
    {
      date: 'March 2023',
      title: 'Founding and Vision',
      description: 'Angel Haven was established with the belief that every individual deserves to age with dignity, comfort, and care. With Gordon Herrick, a critical care registered nurse and Dorothy Schlimme who is also a dedicated member of the Adult Family Home Council for the state of Washington, leading the charge, the home has always prioritized high standards of care and compliance with state regulations.',
    },
    {
      date: 'May 2024',
      title: 'Growth and Expansion',
      description: 'From its humble beginnings, Angel Haven has grown to include three homes that offer a range of care options for residents. Each home is thoughtfully designed to provide a warm, family-like atmosphere, ensuring that residents feel at home while receiving the care they need. Over time, Angel Haven’s reputation for excellence allowed it to expand, and just a year ago, the business proudly opened a fourth home. This new location is run in partnership with Gordon, a registered nurse, and Whitney Herrick, adding another layer of expertise and dedication to the team.',
    },
    {
      date: 'August 2024',
      title: 'Serving the Community',
      description: 'Angel Haven’s homes are spread across Auburn City, allowing them to serve not only Auburn but also nearby cities and towns, making quality care accessible to a larger community. The business has always strived to meet the growing needs of the area, ensuring availability and expanding services as required.',
    },
  ];

  return (
    <div className="container mt-10">
      <h2 className="text-5xl ml-3 font-bold leading-[58.08px] text-zinc-800 max-md:max-w-full max-md:text-4xl text-center mb-10">
        History
      </h2>
      <ol className="relative border-s border-gray-200 dark:border-gray-700 ml-5">
        {historyItems.map((item, index) => (
          <HistoryItem
            key={index}
            date={item.date}
            title={item.title}
            description={item.description}
          />
        ))}
      </ol>
    </div>
  );
}
