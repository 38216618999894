import React from "react";

const testimonialsData = [
  {
    stars: 5,
    quote:
    "Words cannot begin to express how much we appreciate all Job does to care for Curtis.  Not only is he caring, kind and empathetic, he is always smiling which  makes us smile too.  Without fail, he is on top of any situation.  We rest easy when Job is there because we know that he will keep Curtis safe.  Thank you Job, for your outstanding leadership and care.",
    name: "Dara -S",
    title: "Family Member",
  },
  {
    stars: 5,
    quote:
      "We would like to thank Ayub/Jobe for all his professionalism, caring, laughter and support for Jim, and all 3 of us.  He is a bright light in a very dark period of our lives.  Jobe welcomed us into his life with open arms and is always there and willing to help in any manner we need, not to mention a darn good cook!  He is warm and has a hug for a dark day and a hug for a happy moment.  Jim really enjoys working with him and feels very safe in his care.",
    name: "Dianne -L",
    title: "Wife of Resident",
  },
];

export default function Testimonials() {
  return (
    <div className="container mt-10 flex flex-col items-center ">
      <h2 className="text-5xl font-bold text-zinc-800 max-md:text-4xl mt-5">
        Testimonials
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-5">
        {testimonialsData.map((testimonial, index) => (
          <div
            key={index}
            className="max-w-xl w-full bg-gray-50 dark:bg-gray-800 shadow-md rounded-lg overflow-hidden transition-transform duration-300 transform hover:-translate-y-2 hover:scale-105"
          >
            <Testimonial data={testimonial} />
          </div>
        ))}
      </div>
    </div>
  );
}

function Testimonial({ data }) {
  return (
    <div className="p-6">
      <div className="flex items-center justify-center mb-4">
        <div className="flex">
          {[...Array(data.stars)].map((_, index) => (
            <svg
              key={index}
              className="w-5 h-5 me-1 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
          ))}
        </div>
      </div>
      <div className="text-lg leading-7 text-gray-700 dark:text-gray-300">
        {data.quote}
      </div>
      <div className="flex items-center justify-center mt-4">
        <div className="flex flex-col items-center">
          <p className="text-xl font-semibold">{data.name}</p>
          <p className="text-gray-500">{data.title}</p>
        </div>
      </div>
    </div>
  );
}
