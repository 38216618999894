import Header from "../components/Header";
import Service from "../components/Service/Service";
import services from "../assets/headers/services.png";
import Banner from "../components/Home/Banner";
import { Helmet } from "react-helmet";

export default function Services() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AH | Services</title>
        <link rel="canonical" href="https://angelhavenbythecreek.com/services" />
        <meta
          name="description"
          content="More information about Angel Haven by the Creek services."
        />
      </Helmet>
      <Banner />
      <Header title={"Services"} img={services} />
      <Service />
    </div>
  );
}
