import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

export default function NavigationBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-blue-50 dark:bg-gray-900">
      <div className="max-w-screen-xl mx-auto p-4 flex flex-col items-center md:flex-row md:justify-between">
        <div className="flex flex-col items-center md:flex-row">
          <a href="/" className="mb-4 md:mb-0">
            <img src={logo} className="h-20" alt="Logo" />
          </a>
          <div className="flex justify-center w-full md:hidden">
            <button
              onClick={handleMenuToggle}
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-800
               dark:focus:ring-gray-600"
              aria-controls="navbar-default"
              aria-expanded={isMenuOpen ? "true" : "false"}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className={`w-full ${isMenuOpen ? "block" : "hidden"} md:block md:w-auto`} id="navbar-default">
          <ul className="font-medium flex flex-col items-center p-4 md:flex-row md:space-x-8 md:items-center md:mt-0 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:bg-transparent md:border-0 dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-800
          ">
            <li>
              <Link
                to="/"
                className="block py-2 px-3 text-white bg-blue-800 rounded md:bg-transparent md:text-blue-800
                 md:p-0 dark:text-white md:dark:text-blue-500"
                aria-current="page"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-800
                 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-800
                 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-800
                 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-800
                 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/amenities"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-800
                 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-800
                 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Amenities
              </Link>
            </li>
            <li>
              <Link
                to="/cost"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-800
                 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-800
                 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-800
                 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-800
                 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
