import * as React from "react";

function Heading({ children }) {
  return (
    <h2 className="ml-6 text-5xl leading-[58.08px] max-md:max-w-full max-md:text-4xl">
      {children}
    </h2>
  );
}

function Paragraph({ children }) {
  return (
    <p className="mt-4 ml-6 text-lg leading-7 w-[702px] max-md:max-w-full">
      {children}
    </p>
  );
}

function Button({ children }) {
  return (
    <button className="justify-center px-10 py-4 mt-8 ml-6 text-base bg-white text-zinc-800 max-md:px-5 max-md:ml-2.5">
      {children}
    </button>
  );
}

function Questions() {
  return (
    <section className="container flex flex-col items-start px-20 py-20 font-bold text-white bg-gradient-to-r from-indigo-400 to-cyan-400 max-md:px-5 my-10">
      <Heading>Have more questions?</Heading>
      <Paragraph>
        Give us a call or email and we are happy to answer any questions you may have.
      </Paragraph>
      <a href='/contact'>

      <Button>Call Today</Button>
      </a>
    </section>
  );
}

export default Questions;
