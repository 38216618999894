import React from "react";
import Header from "../components/Header";
import Banner from "../components/Home/Banner";
import Living from "../components/Amenities/Living";
import FoodMenu from "../components/Amenities/FoodMenu";
import amenities from "../assets/headers/ameneties.png";
import { Helmet } from "react-helmet";

export default function Amenities() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AH | Ameneties</title>
        <link rel="canonical" href="https://angelhavenbythecreek.com/amenities" />
        <meta
          name="description"
          content="Shows images of the amenities and food menu at Angel Haven by the Creek ."
        />
      </Helmet>
      <Banner />
      <Header title={"Ameneties"} img={amenities} />
      <Living />
      <FoodMenu />
    </div>
  );
}
