import React from "react";
import Hero from "../components/Home/Hero";
import About from "../components/Home/About";
import Services from "../components/Home/Services";
import Ameneties from "../components/Home/Amenities";
import Testimonials from "../components/Home/Testimonials";
import ContactForm from "../components/Contact/ContactForm";
import Map from "../components/Home/Map";
import { Helmet } from "react-helmet";
import Banner from "../components/Home/Banner";

function Home() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AH | Home</title>
        <link rel="canonical" href="https://angelhavenbythecreek.com/" />
        <meta
          name="description"
          content="Home page and overview of Angel Haven by the Creek."
        />
      </Helmet>
      <Banner/>
      <Hero />
      <About />
      <Services />
      <Ameneties />
      <Testimonials />
      <Map />
      <ContactForm />
    </div>
  );
}

export default Home;
