import Header from "../components/Header";
import MissionStatement from "../components/About/Mission";
import History from "../components/About/History";
import Staff from "../components/About/Staff";
import about from "../assets/headers/about.png";
import Banner from "../components/Home/Banner";
import { Helmet } from "react-helmet";
import CaregiverModel from "../components/About/CareGiverModel";

export default function About() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AH | About</title>
        <link rel="canonical" href="https://angelhavenbythecreek.com/about" />
        <meta
          name="description"
          content="Additional information about Angel Haven by the Creek"
        />
      </Helmet>
      <Banner />
      <Header title={"About Us"} img={about} />
      <MissionStatement />
      <CaregiverModel/>
      <History />
      <Staff />
    </div>
  );
}
