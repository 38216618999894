import Header from "../components/Header";
import Banner from "../components/Home/Banner";
import Costs from "../components/Costs/Costs";
import Questions from "../components/Costs/Questions";
import cost from "../assets/headers/cost.png";
import { Helmet } from "react-helmet";

export default function Cost() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AH | Cost</title>
        <link rel="canonical" href="https://angelhavenbythecreek.com/cost" />
        <meta
          name="description"
          content="Contains additional information about costs at Angel Haven by the Creek"
        />
      </Helmet>
      <Banner />
      <Header title={"Costs"} img={cost} />
      <Costs />
      <Questions />
    </div>
  );
}
