import React from "react";

export default function Map() {
  return (
    <div>
      <div className="mt-10 mb-4 text-center lg:mb-0">
      <h2 className="text-5xl font-bold text-zinc-800 max-md:max-w-full max-md:text-4xl mb-5">
             Located at 
            </h2>        <p className="text-3xl mb-5">813 26th St SE, Auburn, WA 98002</p>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2706.476839752247!2d-122.2176327!3d47.2854805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5490f7e9e09995e1%3A0x39db0987a509c44a!2s813%2026th%20St%20SE%2C%20Auburn%2C%20WA%2098002!5e0!3m2!1sen!2sus!4v1716393501367!5m2!1sen!2sus"
          className="w-full"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
