import * as React from "react";

// Import images as modules
import pellyImage from "../../assets/staff/pelly.jpg";
import purityImage from "../../assets/staff/purity.jpg";
import ayubImage from "../../assets/staff/ayub.jpg";
import gordonImage from "../../assets/staff/herrick.jpg";
import whitneyImage from "../../assets/staff/whitney.jpg"; 

// Component for staff card
function StaffCard({ name, designation, description, imageSrc }) {
  return (
    <div className="flex flex-col md:flex-row w-full mb-8 items-center md:items-start text-left">
      {/* Image with responsive sizing */}
      <img
        loading="lazy"
        src={imageSrc}
        alt={`${name}'s headshot`}
        className="w-full md:w-1/4 h-auto object-cover mb-4 md:mb-0 max-w-full"
      />

      {/* Name, title, and description */}
      <div className="flex flex-col w-full md:w-1/2 text-center md:text-left md:ml-6">
        {/* Name and title centered on small screens */}
        <div className="text-2xl font-semibold text-zinc-800">{name}</div>
        <div className="mt-1 font-medium text-zinc-600">{designation}</div>

        {/* Description is centered and wraps properly on small screens */}
        <div className="mt-4 leading-6 text-neutral-500 text-left">
          {description}
        </div>
      </div>
    </div>
  );
}

export default function Staff() {
  const staffData = [
    {
      name: "Pelly",
      designation: "Caregiver",
      description:
        "Pelly has been an integral part of the Angel Haven team for the past 9 months. After raising her two boys, she found her calling in caregiving, a role she describes as doing “God’s work.” Pelly is committed to caring for the vulnerable and takes great joy in building meaningful relationships with both the residents and their families. Her nurturing spirit shines through in everything she does.",
      imageSrc: pellyImage,
    },
    {
      name: "Purity",
      designation: "Caregiver",
      description:
        "Purity has been with us for 6 months and brings a wealth of personal experience to her role, having cared for her grandmother for many years. Now a mother to a 5-year-old daughter, Purity finds deep fulfillment in helping the elderly, seeing her role as becoming an extension of the families she supports. Her compassionate nature and attention to detail make her an invaluable part of our caregiving team.",
      imageSrc: purityImage,
    },
    {
      name: 'Ayub "Jobe"',
      designation: "Senior Caregiver",
      description:
        "Ayub, affectionately known as Jobe, has been an integral part of Angel Haven for over 5 years. A devoted husband and father of two, Jobe is highly respected within the caregiver community for his unwavering commitment to excellence. His diligence and dedication consistently go above and beyond to ensure the well-being of our residents. Jobe believes that service and safety are paramount, making him an invaluable part of our caregiving team.",
      imageSrc: ayubImage,
    },
    {
      name: "Gordon Herrick, RN",
      designation: "House Provider",
      description:
        "Gordon Herrick, a Registered Nurse with over a decade of critical care experience, brings his expertise and compassion to Angel Haven as a house provider. His deep commitment to serving vulnerable populations led him to open a home where elderly care is prioritized. For Gordon, this work is part of a higher calling, serving families with a firm belief that caring for the elderly is part of God’s work.",
      imageSrc: gordonImage,
    },
    {
      name: "Whitney Herrick",
      designation: "House Manager",
      description:
        "As the House Manager at Angel Haven, Whitney Herrick ensures that every aspect of daily life runs smoothly for both residents and staff. From planning nutritious home-cooked meals and curating thoughtful dinner menus to organizing engaging outings, activities, and holiday family gatherings, Whitney brings warmth and care to everything she does.",
      imageSrc: whitneyImage,
    },
  ];

  return (
    <main className="container mt-10 flex flex-col">
      <h2 className="self-center text-5xl font-bold leading-[58.08px] text-zinc-800 max-md:max-w-full max-md:text-4xl">
        Meet Our Care Givers
      </h2>
      <section className="px-5 mt-16 w-full max-md:mt-10 max-md:max-w-full">
        {staffData.map((staff, index) => (
          <StaffCard
            key={index}
            name={staff.name}
            designation={staff.designation}
            description={staff.description}
            imageSrc={staff.imageSrc}
          />
        ))}
      </section>
    </main>
  );
}
