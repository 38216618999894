import React from "react";
import image1 from "../../assets/indoor/1.jpg";
import image2 from "../../assets/indoor/2.jpg";
import image3 from "../../assets/indoor/3.jpg";
import image4 from "../../assets/indoor/4.jpg";
import image5 from "../../assets/indoor/5.jpg";
import image6 from "../../assets/indoor/6.jpg";
import image7 from "../../assets/indoor/7.jpg";
import image8 from "../../assets/indoor/8.jpg";
import image9 from "../../assets/indoor/9.jpg";
import image10 from "../../assets/indoor/10.jpg";
import image11 from "../../assets/indoor/11.jpg";
import image12 from "../../assets/indoor/12.jpg";
import image13 from "../../assets/indoor/13.jpg";
import image14 from "../../assets/indoor/14.jpg";
import outdoor from "../../assets/home/hero.png";
import outdoor1 from "../../assets/outdoor/1.png";
import outdoor2 from "../../assets/outdoor/2.png";
import anmial1 from "../../assets/service_animal/1.png";
import animal2 from "../../assets/service_animal/2.png";
import animal3 from "../../assets/service_animal/3.png";

const indoorRooms = [image1, image2, image3, image4, image5, image6, image7, image8];
const outdoorRooms = [outdoor1, outdoor2];
const serviceAnimal = [anmial1, animal2, animal3];
const entertainment = [image9, image10, image11, image12, image13, image14];

function Gallery() {
  return (
    <div className="container mx-auto px-4 md:px-0">
      <h2 className="text-4xl font-bold text-center my-5">Outdoor</h2>
      <div className="grid md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-10">
        <img
          className="h-50 w-50 rounded-lg"
          alt="Outdoor Facility Photo Hero"
          src={outdoor}
        />
      </div>

      <div className="grid mt-5 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 gap-10">
        {outdoorRooms.map((image, index) => (
          <img
            key={index}
            className="h-50 w-50 rounded-lg"
            alt={`Outdoor Facility Photo ${index}`}
            src={image}
          />
        ))}
      </div>

      <h2 className="text-4xl font-bold text-center mt-10 mb-5">Indoor</h2>
      <div className="grid md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-10">
        {indoorRooms.map((image, index) => (
          <img
            key={index}
            className="h-50 max-w-full rounded-lg"
            alt={`Indoor facility photo ${index}`}
            src={image}
          />
        ))}
      </div>

      <h2 className="text-4xl font-bold text-center mt-10">Service Animal</h2>
      <p className="text-center text-gray-500 dark:text-gray-400 mb-5 mt-5">
        We are in the midst of training a new service animal that will keep you company.
      </p>
      <div className="grid md:grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 gap-10">
        {serviceAnimal.map((image, index) => (
          <img
            key={index}
            className="h-50 max-w-full rounded-lg"
            alt={`Service animal photo ${index}`}
            src={image}
          />
        ))}
      </div>

      <h2 className="text-4xl font-bold text-center mt-10">Entertainment</h2>
      <p className="text-center text-gray-500 dark:text-gray-400 mb-5 mt-5">
        Our guests are actively engaged in the community and enjoy each other's company.
      </p>
      <div className="grid md:grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 gap-10">
        {entertainment.map((image, index) => (
          <img
            key={index}
            className="h-50 max-w-full rounded-lg"
            alt={`Entertainment photo ${index}`}
            src={image}
          />
        ))}
      </div>
    </div>
  );
}

export default Gallery;
