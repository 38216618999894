import * as React from "react";

export default function CaregiverModel() {
  return (
    <section className="container mt-10 flex gap-5 max-md:flex-col max-md:gap-0">
      <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
        <div className="flex flex-col self-stretch px-5 my-auto max-md:mt-10 max-md:max-w-full">
          <h2 className="text-5xl font-bold text-center text-zinc-800 max-md:max-w-full max-md:text-4xl">
            Our Caregiver Model
          </h2>
          <p className="text-lg leading-7 text-neutral-500 max-md:max-w-full mt-5">
            At Angel Haven, we prioritize consistent, compassionate care provided by a dedicated team of live-in caregivers. Our unique model ensures that residents always have access to familiar and trusted caregivers who understand their individual needs. Here's how our caregiver model works:
          </p>
          <div className="mt-8 space-y-8">
            <div>
              <h3 className="text-2xl font-semibold text-zinc-800 mb-2">
                Live-In Caregiving Team
              </h3>
              <p className="text-lg leading-7 text-neutral-500">
                We have a team of four live-in caregivers, providing continuous care and supervision around the clock. This model ensures consistency, as residents build relationships with a small, familiar group of caregivers who are always present in the home.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-zinc-800 mb-2">
                24-Hour Care and Attention
              </h3>
              <ul className="list-disc list-inside text-lg leading-7 text-neutral-500">
                <li className="mb-2">
                  <span className="font-semibold">Wake Night Staff:</span> One caregiver is specifically dedicated to overnight care, ensuring that residents’ needs are met during the night. This wake night staff member is alert and available to handle any nighttime assistance required, from medication needs to providing comfort and safety.
                </li>
                <li>
                  <span className="font-semibold">Day Shift Rotation:</span> The other three caregivers rotate through day shifts, providing personalized care, assistance with daily activities, and fostering a warm, engaging environment. This rotation ensures that caregivers remain well-rested and attentive, while residents receive the highest level of care during the day.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-zinc-800 mb-2">
                Personalized and Consistent Care
              </h3>
              <p className="text-lg leading-7 text-neutral-500">
                With a small, dedicated team, our caregivers are able to develop deep, meaningful relationships with residents. This consistency allows caregivers to better understand each resident’s preferences, routines, and specific care requirements, creating a personalized care plan that evolves with their needs.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-zinc-800 mb-2">
                Expert Training and Compassion
              </h3>
              <p className="text-lg leading-7 text-neutral-500">
                All of our caregivers are highly trained professionals, skilled in geriatric care, dementia support, and chronic illness management. Their training is complemented by a genuine passion for caregiving, ensuring that residents not only receive expert care but also feel valued and understood.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
