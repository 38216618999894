import * as React from "react";

function Costs() {
  return (
    <section className="container my-5 flex flex-col">
      <h2 className="self-center ml-5 text-5xl font-bold leading-[58.08px] text-zinc-800 max-md:max-w-full max-md:text-4xl">
        Payment and Costs
      </h2>
      <p className="mt-2 ml-2 text-lg leading-7 text-neutral-500 max-md:max-w-full px-4">
        We accept private pay and Medicare per contract. Rates are based off of acuity and
        nursing care hours required. Cost covers activity of daily living care,
        nutrition, medication administration, and more. Please contact us for more details. 
      </p>
    </section>
  );
}

export default Costs;
