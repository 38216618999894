import * as React from "react";
import customer from "../../assets/home/customer.jpg";
import music from "../../assets/music/1.png";

function IconWithText({ icon, text, is_new }) {
  return (
    <div className="flex gap-2 items-start leading-[162.5%] max-md:flex-wrap mt-5">
      <img
        loading="lazy"
        src={icon}
        alt="Bullet Icon"
        className="shrink-0 w-6 aspect-square"
      />
      <p className="max-w-[calc(100%-2rem)]">{text}
      {is_new && (
        <span class="ml-3 bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">New!</span>
      )} 
      </p>{" "}
      {/* Adjust max-width to leave space for gap */}
    </div>
  );
}

export default function Ameneties() {
  const iconTextData = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/efbd10c4799084d6c26f208df8f42b2116025b662fe3413ae7474be7b747cae1?apiKey=407d704e01f2493092f36d784e4d9811&",
      text: "Training a service dog for the house - will be in training for cardiac and seizure detections",
      is_new: true,
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/efbd10c4799084d6c26f208df8f42b2116025b662fe3413ae7474be7b747cae1?apiKey=407d704e01f2493092f36d784e4d9811&",
      text: "Impeccably clean and comfortable living spaces",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/efbd10c4799084d6c26f208df8f42b2116025b662fe3413ae7474be7b747cae1?apiKey=407d704e01f2493092f36d784e4d9811&",
      text: "Delicious, home cooked meals prepared with care",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/efbd10c4799084d6c26f208df8f42b2116025b662fe3413ae7474be7b747cae1?apiKey=407d704e01f2493092f36d784e4d9811&",
      text: "Shuttle bus to get out and stay engaged with the community",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/efbd10c4799084d6c26f208df8f42b2116025b662fe3413ae7474be7b747cae1?apiKey=407d704e01f2493092f36d784e4d9811&",
      text: "Engaging games and activities to keep residents active and entertained",
    },
  ];

  return (
    <section className="container mt-10 flex gap-5 max-md:flex-col max-md:gap-0">
      <div className="flex flex-col w-10/12 max-md:ml-0 max-md:w-full">
        <div className="flex flex-col self-stretch px-5 my-auto text-base text-zinc-500 max-md:mt-10 max-md:max-w-full">
          <h2 className="text-center text-5xl leading-[58px] text-zinc-800 font-bold max-md:max-w-full max-md:text-4xl max-md:leading-[54px]">
            Ameneties
          </h2>
          <p className="mt-4 text-lg leading-7 text-zinc-500 max-md:max-w-full">
            We take the workload off families, providing peace of mind and
            support every step of the way. At Angel Haven by the creek, we treat
            residents how we would like our grandparents to be treated, with
            respect, dignity, and genuine care. Come experience the comfort and
            peace of mind that comes with exceptional care at Angel Haven by the
            Creek.{" "}
          </p>
          <div>
            {iconTextData.map((item, index) => (
              <IconWithText
                key={index}
                icon={item.icon}
                text={item.text}
                is_new={item.is_new}
              />
            ))}
          </div>
          <p className="text-center">
            Want to learn more?
            <a
              href="/amenities"
              className="inline-flex items-center font-medium text-blue-700 dark:text-blue-500 hover:underline ml-2 mt-5"
            >
              See home photos and food menu
              <svg
                className="w-4 h-4 ms-2 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </p>
        </div>
      </div>
      <div className="h-auto max-h-full max-w-full overflow-hidden mt-5">
        <img
          loading="lazy"
          src={music}
          alt="Enjoying meal together"
          className="w-auto h-full object-cover max-md:w-full max-md:h-auto"
        />
      </div>
    </section>
  );
}
