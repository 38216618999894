import * as React from "react";

function Header({title, img}) {
  return (
    <section className="overflow-hidden relative flex-col justify-center items-start pt-40 pr-16 pb-20 pl-24 text-6xl font-bold text-white leading-[67.8px]  max-md:pt-10 max-md:pr-5 max-md:pl-8 max-md:text-4xl">
      <img
        loading="lazy"
        src={img}
        alt="Page Title Background"
        className="absolute inset-0 h-auto"
      />
      <h2 className="relative mt-5">{title}</h2>
    </section>
  );
}

export default Header;
