import React from "react";
import ContactForm from "../components/Contact/ContactForm";
import { Helmet } from "react-helmet";

export default function Contact() {
  return (
    <div className="vh-100">
      <Helmet>
        <meta charSet="utf-8" />
        <title>AH | Contact</title>
        <link rel="canonical" href="https://angelhavenbythecreek.com/contact" />
        <meta
          name="description"
          content="Contact Form for Angel Haven by the Creek."
        />
      </Helmet>
      <ContactForm />
    </div>
  );
}
