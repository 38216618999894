import React from "react";
import { useState } from "react";
import { post } from "@aws-amplify/api";

function ContactForm() {
  const initialFormData = {
    subject: { value: "", error: "" },
    email: { value: "", error: "" },
    message: { value: "", error: "" },
    status: 0,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formSuccess, setFormSuccess] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: { value, error: "" },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setRefreshing(true);
      setFormErrors(false);
      setFormSuccess(false);

      const response = await post({
        apiName: "angelhavenapi",
        path: "/contact",
        options: {
          body: formData,
          headers: {
            "Content-Type": "application/json",
          },
        },
      }).response;

      const data = await response.body.json();
      setFormData({
        subject: {
          value: data.subject.value || "",
          error: data.subject.error || "",
        },
        email: { value: data.email.value || "", error: data.email.error || "" },
        message: {
          value: data.message.value || "",
          error: data.message.error || "",
        },
        status: data.status,
      });

      switch (data.status) {
        case 200:
          setFormSuccess(true);
          break;
        case 400:
          break;
        case 500:
          setFormErrors(true);
          break;
        default:
          setFormErrors(true);
          break;
      }
    } catch (error) {
      setFormErrors(true);
      console.error(error);
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <div className="container mt-10 mx-auto px-4 sm:px-6 lg:px-8">
      <section id="contact" className="bg-white dark:bg-gray-900 mb-10 p-4 rounded-lg shadow-lg">
        <h2 className="text-center text-5xl font-bold text-zinc-800 max-md:max-w-full max-md:text-4xl mb-4">
          Contact Us
        </h2>
        <div className="py-8 px-4 mx-auto max-w-screen-xl flex flex-col lg:flex-row">
          {/* Contact Us */}
          <div className="w-full lg:w-1/2 pr-0 lg:pr-12 mb-8 lg:mb-0">
            <p className="mb-8 font-light text-gray-500 dark:text-gray-400 sm:text-xl">
              We're here to help. If you would like to schedule a tour or have any questions, please feel free to reach out to us.
            </p>

            <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
              <div className="flex flex-col pb-3">
                <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                  Email address
                </dt>
                <a href="mailto:gordon@angelhavenbythecreek.com" className="text-lg font-semibold">
                  gordon@angelhavenbythecreek.com
                </a>
              </div>
              <div className="flex flex-col pt-3">
                <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                  Phone number
                </dt>
                <a href="tel:2532797919" className="text-lg font-semibold">+1 (253) 279-7919</a>
              </div>
              <div className="flex flex-col pt-3">
                <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                  Fax
                </dt>
                <a href="tel:2537374437" className="text-lg font-semibold">+1 (253)-737-4437</a>
              </div>
              <div className="flex flex-col py-3">
                <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                  Home address
                </dt>
                <dd className="text-lg font-semibold">
                  813 26th St SE, Auburn, WA 98002
                </dd>
              </div>
            </dl>
          </div>
          {/* Form */}
          <form className="w-full lg:w-1/2" onSubmit={handleSubmit}>
            {/* Email Input */}
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Your email
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="name@youremail.com"
                value={formData.email.value}
                onChange={(e) => handleInputChange(e, "email")}
                required
              />
              {formData.email.error && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  {formData.email.error}
                </p>
              )}
            </div>

            {/* Subject Input */}
            <div className="mb-6">
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Let us know how we can help you"
                value={formData.subject.value}
                onChange={(e) => handleInputChange(e, "subject")}
                required
              />
              {formData.subject.error && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  {formData.subject.error}
                </p>
              )}
            </div>

            {/* Message Input */}
            <div className="mb-6">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Your message
              </label>
              <textarea
                id="message"
                rows="6"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Leave a comment..."
                value={formData.message.value}
                onChange={(e) => handleInputChange(e, "message")}
                required
              ></textarea>
              {formData.message.error && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  {formData.message.error}
                </p>
              )}
            </div>

            {/* Submit Button */}
            {refreshing ? (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <button
                type="submit"
                className="w-full justify-center self-start py-4 font-bold text-white bg-[linear-gradient(135deg,#7F9CF5_0%,#22D3EE_100%)] max-md:px-5 max-md:mt-10"
              >
                Send message
              </button>
            )}
            {formSuccess && (
              <div
                className="mt-5 flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                role="alert"
              >
                <svg
                  className="flex-shrink-0 inline w-4 h-4 me-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Info</span>
                <div>
                  <span className="font-medium">Success!</span> We will get back to you soon
                </div>
              </div>
            )}

            {formErrors && (
              <div
                className="mt-3 flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
                role="alert"
              >
                <svg
                  className="flex-shrink-0 inline w-4 h-4 me-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Info</span>
                <div>
                  <span className="font-medium">Internal Server Error</span> Please try again later
                </div>
              </div>
            )}
          </form>
        </div>
      </section>
    </div>
  );
}

export default ContactForm;
